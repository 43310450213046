import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import Sidebar from "./components/SideBar";
import ProtectedRoute from "./components/ProtectedRoute";
import Auth from "./pages/auth";
import Landing from ".//pages/landing"



import { RoutesX } from "./utils/RoutesX";

import useAuthStore from "./stores/useAuthStore";

import AuthService from "./services/AuthService";

//TODO Add Error Handling to api's
  // Login - Wrong credential - wrong format 
  // Register - Wrong email format - role - current_station

function App() {
  const setAuth = useAuthStore((state) => state.setAuth);
  const setToken = useAuthStore((state) => state.setToken);
  const checkAuthenticated = async () => {
    try {
      console.log(`App.js checkAuthenticated`);

 
       const response = await AuthService.verify()


      console.log(`Verify Request : ${response === true}`);

      response === true ? setAuth(true) : setAuth(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
    checkAuthenticated();
  }, [setToken, setAuth]);
  return (
    <Router>
      <div className="flex">
        {/* <Sidebar /> */}

        <div className="flex-grow overflow-auto">
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="*" element={<Landing/>} />
            <Route path="/" element={<Landing/>} />
            {RoutesX.map((route) => (
              <Route
                path={route.to}
                key={route.to}
                element={
                  route.protectedRoute ? (
                    <ProtectedRoute component={route.component} />
                  ) : (
                    <route.component />
                  )
                }
              />
            ))}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
