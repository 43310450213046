import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

import ButtonX from "../../../components/ButtonX";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../../stores/useUserStore";
import useAuthStore from "../../../stores/useAuthStore";

function Navbar() {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const { name, email } = useUserStore();
  const setAuth = useAuthStore((state) => state.setAuth);
  const [leftSpaceError, setLeftSpaceError] = useState("");

  const [leftSpace, setLeftSpace] = useState();

  const handleNav = () => {
    setNav(!nav);
  };

  function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const validatePadding = () => {
    if (!isNumber(leftSpace)) {
      setLeftSpaceError("Please enter a valid number");
    } else {
      setLeftSpaceError("");
    }
  };

  return (
    <div className="bg-white">
      <div
        className="
    flex 
    justify-between
    items-center
    text-white
    h-24
    w-full
    max-w-[1240px]
    mx-auto
    px-4
    "
      >
        <h1 className="w-full text-3xl font-bold text-[#eb6d20] ">TegoMerge</h1>

        <div onClick={handleNav} className=" hover:cursor-pointer text-black">
          {nav ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <div
          className={
            nav
              ? `fixed right-0 top-0 w-[60%] max-w-[400px] h-full
            border-r border-r-gray-900
            bg-white
            ease-in-out duration-500
           `
              : `fixed right-[-100%] top-0
           ease-in-out duration-700
           `
          }
        >
          <div className="flex pr-2">
            <h1 className="w-full text-3xl font-bold text-[#eb6d20] m-4">
              Settings
            </h1>

            <ButtonX
              className={"mr-4"}
              variant={"dark-outline"}
              name={"Close"}
              onClick={handleNav}
            />
          </div>

          <ul className=" text-black">
            <li className="p-4 border-b border-gray-600">Username: {name}</li>
            <li className="p-4 border-b border-gray-600">Email: {email}</li>

            <li className="p-4 border-b border-gray-600">
              Contact: melikhan.hosdogdu@gmail.com
            </li>

            <li>
              <div className="pt-4 pl-4 pr-4">
                <label className="">
                  Set Left Space: {localStorage.getItem("leftSpace")}
                </label>
                <div className="flex items-center">
                  <input
                    className="w-full border-2 border-gray-100 focus:outline-none focus:border-[#eb6d20] rounded-xl p-4 mt-1 bg-transparent"
                    type="email"
                    placeholder="Set Left Space"
                    onBlur={validatePadding}
                    onChange={(e) => {
                      setLeftSpace(e.target.value);
                      setLeftSpaceError("");
                    }}
                  />
                  <div className="pl-4">
                    <ButtonX
                      variant={"danger-outline"}
                      name={"Apply"}
                      onClick={() => {
                        validatePadding();
                        if (leftSpaceError === "") {
                          localStorage.setItem(`leftSpace`, leftSpace);
                          setLeftSpaceError(" ");

                        }
                      }}
                    />
                  </div>
                </div>
                {
                  <p className="pt-2 pl-2 text-red-500">
                    {leftSpaceError !== "" ? leftSpaceError : ""}
                  </p>
                }
              </div>
            </li>
            <li className="p-4 text-gray-300">Version: 1.2.5</li>
            <li className="pl-4 pt-4">
              <ButtonX
                variant={"danger-outline"}
                name={"Log Out"}
                onClick={() => {
                  navigate("/");
                  localStorage.removeItem(`token`);
                  localStorage.removeItem(`refreshToken`);
                  setAuth(false);
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
