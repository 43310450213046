import React, { useState } from "react";
import ButtonX from "../../../../components/ButtonX";
import { useNavigate } from "react-router-dom";
import { notifyErrorByParameter } from "../../../../utils/SnackBarUtils";
import PrintAuthService from "../../../../services/PrintAuthService";
import { ToastContainer } from "react-toastify";

import useAuthStore from "../../../../stores/useAuthStore";
function Form() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const setAuth = useAuthStore((state) => state.setAuth);
  const setToken = useAuthStore((state) => state.setToken);

  const validateEmail = () => {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegEx.test(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (password.length === 0) {
      setPasswordError("Password can not be empty");
    } else {
      setPasswordError(``);
    }
  };
  const validateName = () => {
    if (name.length === 0) {
      setNameError("Name can not be empty");
    } else {
      setNameError("");
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("submit 3");
    validateEmail();
    validatePassword();
    validateName();

    if (emailError !== "" || passwordError !== "") return;

    if (!isLogin && nameError !== "") return;

    let response;
    try {
      // setLoading(true);
      // setAuth(true);
      // navigate("/merge");
      // setLoading(false);
      if (isLogin) {
        const loginCredential = { email, password };
        // response = await PrintAuthService.login(loginCredential);
        navigate("/merge");
      } else {
        const registerCredential = {
          organizationName: "TegoMerge",
          phone: `123${Date.now().toString().slice(-4)}${Math.floor(
            1000 + Math.random() * 9000
          )}`,

          language: "en",
          email,
          password,
          name,
        };
        response = await PrintAuthService.register(registerCredential);
      }
      console.log(response);
      if (response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("refreshToken", response.refreshToken);
        setToken(response.token);
        setAuth(true);
        navigate("/merge");
      } else {
      }
    } catch (error) {
      console.log(error);
      notifyErrorByParameter(`${error}`);
      setAuth(false);
      console.log(`sdknksdjnvskdj`);
    }
    setLoading(false);
  };
  return (
    <div className="bg-white px-10 py-20 mx-10  rounded-3xl border-2 border-gray">
      <h1 className="text-5xl font-semibold">
        {isLogin ? "Welcome Back" : "Register"}
      </h1>
      <p className="font-medium text-lg text-gray-500 mt-4">
        {isLogin
          ? "Welcome back! Please enter you information"
          : "Welcome to TegoMerge, Let's start your journey"}
      </p>
      <div className="mt-8">
        {/* Name */}
        <div className={`${isLogin ? "hidden" : ""}`}>
          <label className="text-lg font-medium">Name</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent focus:outline-none focus:border-[#eb6d20]"
            type="text"
            placeholder="Enter your name"
            onBlur={validateName}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
          />
          {
            <p className="pt-2 pl-2 text-red-500">
              {" "}
              {nameError !== "" ? nameError : ""}
            </p>
          }
        </div>
        {/* E mail */}
        <div>
          <label className="text-lg font-medium">Email</label>
          <input
            className="w-full border-2 border-gray-100 focus:outline-none focus:border-[#eb6d20] rounded-xl p-4 mt-1 bg-transparent"
            type="email"
            placeholder="Enter your email"
            onBlur={validateEmail}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(""); // Clear error when user starts typing
            }}
          />
          {
            <p className="pt-2 pl-2 text-red-500">
              {emailError !== "" ? emailError : ""}
            </p>
          }
        </div>
        {/* Password */}
        <div>
          <label className="text-lg font-medium">Password</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent focus:outline-none focus:border-[#eb6d20]"
            type="password"
            placeholder="Enter your password"
            onBlur={validatePassword}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(""); // Clear error when user starts typing
            }}
          />
          {
            <p className="pt-2 pl-2 text-red-500">
              {passwordError !== "" ? passwordError : ""}
            </p>
          }
        </div>
        {/* Button  */}
        <div>
          <ButtonX
            name={isLogin ? "Login" : "Register"}
            width={"w-full"}
            loading={loading}
            onClick={onSubmit}
          />
        </div>
        {/* Have an account ? */}
        <div className="mt-4 flex justify-center items-center">
          <p className="font-medium text-base">
            {" "}
            {isLogin ? "Is your first time" : "Do you have an account"}{" "}
          </p>
          <button
            onClick={() => {
              setIsLogin(!isLogin);
            }}
            className="text-[#eb6d20] text-base font-medium ml-2"
          >
            {isLogin ? "Register" : "Login"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Form;
