import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingX from "./LoadingX";
import useAuthStore from "../stores/useAuthStore";
import useUserStore from "../stores/useUserStore";

import AuthService from "./../services/AuthService";
import UserService from "./../services/UserService";

const ProtectedRoute = ({ component: Component }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setAuth = useAuthStore((state) => state.setAuth);
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate();
  const { isUserActive } = useUserStore();
  const token = localStorage.getItem("token");
  const checkAuthenticated = async () => {
    try {
      const response = await AuthService.verify();
      response === true ? setAuth(true) : setAuth(false);
      if (response === false) {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  const getUserInformation = async () => {
    try {
      const response = await UserService.getMe();
      

      setUser(response);
      checkUserStatus();
    } catch (err) {
      console.error(err.message);
    }
  };
  const checkUserStatus = async () => {
    const isActive = await isUserActive(useUserStore.getState);
    if (!isActive) {
      navigate("/payment");
      // localStorage.removeItem("token");
      // localStorage.removeItem("refreshToken");
    } else {
      navigate("/merge");
    }
  };

  useEffect(() => {
    checkAuthenticated();
    getUserInformation();

    console.log("protected");
    if (!isAuthenticated && !token) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? (
    <Component />
  ) : (
    <div className="h-screen w-full flex justify-center items-center">
      <LoadingX />
    </div>
  );
};

export default ProtectedRoute;
