import api from "./api";

const AuthService = {
  register: async (registerData) => {
    try {
      const response = await api.post("/auth/merge_qr_register", registerData);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  login: async (loginData) => {
    try {
      const response = await api.post("/auth/login", loginData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  verify: async () => {
    try {
      // const response = await api.post("/auth/verify");
      return true;
      //  response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  refreshToken: async () => {
    try {
      const response = await api.post("/auth/refresh");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default AuthService;
